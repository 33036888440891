import styles from './Timer.module.css';

export default function Timer({runningTime, maxTime, active=true, label=null, stopped}) {
    const maxInMillis = maxTime * 1000;
    let remainingTime = Math.max((maxInMillis - runningTime), 0);
    
    if (stopped) {
        remainingTime = 0
    }

    let color = remainingTime == 0 ? styles.red : styles.normal
    let activeStyle = active === true ? styles.active : styles.inactive


    return (
        <>
            {label && <span className={styles.label}>{label}</span>}
            <span className={`${styles.counter} ${color} ${activeStyle}`}>{Math.floor(Math.floor((remainingTime / 1000 / 60) % 60)).toString().padStart(2, "0")}:{Math.floor((remainingTime / 1000) % 60).toString().padStart(2, "0")}:{Math.floor(remainingTime % 1000 / 10).toString().padStart(2, "0") }</span>
        </>
    )
  }