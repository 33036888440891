import styles from './Controls.module.css';
import { saveAs } from 'file-saver';
import React, {useEffect} from 'react';

function checkIntegrity(fileContent) {
  const mandatoryFields = [
    "state",
    "teamAName",
    "teamAPlayers",
    "teamAScore",
    "teamBName",
    "teamBPlayers",
    "teamBScore",
    "gameTime",
    "timeoutTime",
    "halftimeTime",
    "overtimeTime",
    "halftimeTimerComplete",
    "timeoutTimerComplete",
    "gameTimerComplete",
    "overtimeTimerComplete"
  ]
  for (const field of mandatoryFields) {
    if (!Object.hasOwn(fileContent, field)) {
      return false
    }
  }
  return true
}


export default function Controls({
  gameStopwatch, 
  halftimeStopwatch, 
  timeoutStopwatch, 
  overTimeStopwatch,
  setGameState, 
  gameState,
  gameData, 
  initGame, 
  reset,
  setTimeoutTimerComplete,
  setHalftimeTimerComplete,
  setGameTimerComplete,
  setOverTimeTimerComplete
}) {
      // Handle clicks
  
      // const updateOnClick = (e) => {
      //   if (e.keyCode == 32) {
      //     startPauseResume()
      //     e.preventDefault()
      //   }
      // }
    
      // useEffect(() => {
      //   document.addEventListener("keydown", updateOnClick, false);
      //   return () => {
      //     document.removeEventListener("keydown", updateOnClick, false);
      //   }
      // }, []);

    const gameSetup = () => {
        resetTimerComplete()
        halftimeStopwatch.stop()
        timeoutStopwatch.stop()
        overTimeStopwatch.stop()
        setGameState("running")
    }

    const startPauseResume = () => {
        resetTimerComplete()
        halftimeStopwatch.stop()
        timeoutStopwatch.stop()
        overTimeStopwatch.stop()
        if (gameStopwatch.isStopped()) {
          setGameState("running");
          gameStopwatch.start();
        }
        else if (gameStopwatch.isPaused()) {
          setGameState("running");
          gameStopwatch.resume();
        }
        else if (gameStopwatch.isRunning()) {
          setGameState("paused");
          gameStopwatch.pause();
        }
      }

    const resetTimerComplete = () => {
      setTimeoutTimerComplete(false)
      setHalftimeTimerComplete(false)
      setGameTimerComplete(false)
      setOverTimeTimerComplete(false)
    }
    const stop = () => {
        gameStopwatch.stop();
        timeoutStopwatch.stop()
        overTimeStopwatch.stop()
        halftimeStopwatch.stop()
        setGameState("paused")
    }

    const timeout = () => {
        resetTimerComplete()
        gameStopwatch.pause()
        timeoutStopwatch.stop()
        overTimeStopwatch.stop()
        halftimeStopwatch.stop()
        setGameState("timeout")
    }

    const timeoutStartStop = () => {
      gameStopwatch.pause()
      overTimeStopwatch.stop()
      halftimeStopwatch.stop()
      setGameState("timeout")
      if (timeoutStopwatch.isStopped()) {
        timeoutStopwatch.start();
      }
      else if (timeoutStopwatch.isPaused()) {
        timeoutStopwatch.resume();
      }
      else if (timeoutStopwatch.isRunning()) {
        timeoutStopwatch.pause();
      }
    }

    const halftime = () => {
        resetTimerComplete()
        gameStopwatch.stop()
        timeoutStopwatch.stop()
        overTimeStopwatch.stop()
        halftimeStopwatch.stop()
        setGameState("halftime")
    }

    const halftimeStartStop = () => {
      gameStopwatch.stop()
      timeoutStopwatch.stop()
      overTimeStopwatch.stop()
      setGameState("halftime")
      if (halftimeStopwatch.isStopped()) {
        halftimeStopwatch.start();
      }
      else if (halftimeStopwatch.isPaused()) {
        halftimeStopwatch.resume();
      }
      else if (halftimeStopwatch.isRunning()) {
        halftimeStopwatch.pause();
      }
  }

    const overTime = () => {
      resetTimerComplete()
      gameStopwatch.stop()
      timeoutStopwatch.stop()
      overTimeStopwatch.stop()
      halftimeStopwatch.stop()
      setGameState("overtime")
    }

    const overTimeStartStop = () => {
      gameStopwatch.stop()
      gameStopwatch.stop()
      timeoutStopwatch.stop()
      halftimeStopwatch.stop()
      setGameState("overtime")
      if (overTimeStopwatch.isStopped()) {
        overTimeStopwatch.start();
      }
      else if (overTimeStopwatch.isPaused()) {
        overTimeStopwatch.resume();
      }
      else if (overTimeStopwatch.isRunning()) {
        overTimeStopwatch.pause();
      }
    }

    const exportData = (status) => {
      console.log(gameData)
      var fileNamePrefix = `${gameData.teamAName}_vs_${gameData.teamBName}_`;
      const now = new Date()
      var formattedDate = `${now.getFullYear()}-${now.getMonth()}-${now.getDate()}--${now.getHours()}-${now.getMinutes()}-${now.getSeconds()}`
      var fileName = fileNamePrefix + status + "_" + formattedDate
      
      // Create a blob of the data
      var fileToSave = new Blob([JSON.stringify(gameData, undefined, 2)], {
          type: 'application/json'
      });
      
      // Save the file
      saveAs(fileToSave, fileName);
    }

    const end = () => {
      setGameState("end")
      exportData("completed")
      resetTimerComplete()
      halftimeStopwatch.stop()
      timeoutStopwatch.stop()
      gameStopwatch.stop()
      overTimeStopwatch.stop()
      reset()
    }

    const save = () => {    
      exportData("ready")
    }

    const load = (e) => {    
      var files = e.target.files;
      var file = files[0];
      var reader = new FileReader();

      reader.onload = function(e) {
        const content = JSON.parse(e.target.result);
        if (checkIntegrity(content)) {
          console.log("Load game");
          initGame(content)
        }
        else {
          console.log("invalid file")
        }
      }
      reader.readAsText(file)

    }

    return (
        <div className={styles.buttonsLayout}>
          <div className={styles.row}>
            <div className={`${styles.multiInputButtons} ${(gameState == "running" || gameState == "paused") ? styles.active : styles.notActive}`}>
              <button className={styles.buttonsLabel} onClick={gameSetup}>Match</button>
              <button className={styles.buttonsControl} onClick={startPauseResume}>{gameStopwatch.isRunning() ? 'Pause': 'Start'}</button>
            </div>
            <div className={`${styles.multiInputButtons} ${gameState == "timeout" ? styles.active : styles.notActive}`}>
              <button className={styles.buttonsLabel} onClick={timeout}>Temps mort</button>
              <button className={styles.buttonsControl} onClick={timeoutStartStop}>{timeoutStopwatch.isRunning() ? 'Pause': 'Start'}</button>
            </div>
            <div className={`${styles.multiInputButtons} ${gameState == "halftime" ? styles.active : styles.notActive}`}>
              <button className={styles.buttonsLabel} onClick={halftime}>Mi-temps</button>
              <button className={styles.buttonsControl} onClick={halftimeStartStop}>{halftimeStopwatch.isRunning() ? 'Pause': 'Start'}</button>
            </div>
            <div className={`${styles.multiInputButtons} ${gameState == "overtime" ? styles.active : styles.notActive}`}>
              <button className={styles.buttonsLabel} onClick={overTime}>But en or</button>
              <button className={styles.buttonsControl} onClick={overTimeStartStop}>{overTimeStopwatch.isRunning() ? 'Pause': 'Start'}</button>
            </div>
            <button className={styles.buttons} onClick={stop}>Stop</button>
          <button className={styles.buttons} onClick={end}>Mise à zéro</button>

          </div>
          <div className={styles.row}>

          </div>

          <div className={styles.row}>
            <button className={styles.expertButtons} onClick={save}>Sauvegarder</button>
            <input className={styles.inputFile} onChange={load} type="file" id="file" name="file" encType="multipart/form-data" accept='.json'/>
            <label className={styles.expertButtons} for="file">Charger une partie</label>
          </div>
        </div>
    )
  }
  
  