import styles from './Logos.module.css';

export default function Logos() {

    return (
        <div className={styles.logoContainer}>
              <div className={styles.fixedLogo}>
                  <img src='/logos/CD71-cropped.svg'/>
                  <img src='/logos/CLUNY-cropped.svg'/>
              </div>
              <div className={styles.movingLogosContainer}>
                <div className={styles.movingLogos}>
                  <span className={styles.blank}></span>
                  <img src='/logos/FFE-cropped.svg'/>
                  <span className={styles.blank}></span>
                  <img src='/logos/REGION-cropped.svg'/>
                  <span className={styles.blank}></span>
                  <img src='/logos/CAVE_DE_LUGNY-cropped.svg'/>
                  <span className={styles.blank}></span>
                  <img src='/logos/COVAREL-cropped.svg'/>
                  <span className={styles.blank}></span>
                  <img src='/logos/DS-cropped.svg'/>
                  <span className={styles.blank}></span>
                  <img src='/logos/FIDAL-cropped.svg'/>
                  <span className={styles.blank}></span>
                  <img src='/logos/Groupama-cropped.svg'/>
                  <span className={styles.blank}></span>
                  <img src='/logos/GUILLET-cropped.svg'/>
                  <span className={styles.blank}></span>
                  <img src='/logos/EQUITA-cropped.svg'/>
                  <span className={styles.blank}></span>
                  <img src='/logos/WHBO-cropped.svg'/>
                  <span className={styles.blank}></span>

                  <img src='/logos/FFE-cropped.svg'/>
                  <span className={styles.blank}></span>
                  <img src='/logos/REGION-cropped.svg'/>
                  <span className={styles.blank}></span>
                  <img src='/logos/CAVE_DE_LUGNY-cropped.svg'/>
                  <span className={styles.blank}></span>
                  <img src='/logos/COVAREL-cropped.svg'/>
                  <span className={styles.blank}></span>
                  <img src='/logos/DS-cropped.svg'/>
                  <span className={styles.blank}></span>
                  <img src='/logos/FIDAL-cropped.svg'/>
                  <span className={styles.blank}></span>
                  <img src='/logos/Groupama-cropped.svg'/>
                  <span className={styles.blank}></span>
                  <img src='/logos/GUILLET-cropped.svg'/>
                  <span className={styles.blank}></span>
                  <img src='/logos/EQUITA-cropped.svg'/>
                  <span className={styles.blank}></span>
                  <img src='/logos/WHBO-cropped.svg'/>
                </div>
              </div>
              
            </div>
    )
  }
  
  