// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Player_player_container__SDoY3 {
    font-size: 5.3vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.Player_player_counter__I-PO\\+ {
    display: block;
    width: 4vw;
    background-color: black;
    color: white;
}

.Player_controls__b-WT3 {
    display: flex;
    flex-direction: row;
}

.Player_controlButtons__MPwNK {
    text-align: center;
    font-size: 1vw;
    font-weight: 900;
    width: 5vh;
    height: 5vh;
}

.Player_numberInput__g8IK3 {
    width: 2vw;
    font-size: 3vh;
}

.Player_playerInput__c5yQF {
    font-size: 3vh;
    width: 8vw;
}

.Player_radio__mXqKn {
    --primary: #f3f3f4;
    --secondary: #FAFBFF;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    outline: none;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transform-style: preserve-3d;
    perspective: 240px;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    border: 4px solid var(--primary);
    &:checked {
        background-color: red;
    }

}
`, "",{"version":3,"sources":["webpack://./src/components/Player.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,cAAc;IACd,UAAU;IACV,uBAAuB;IACvB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,gBAAgB;IAChB,UAAU;IACV,WAAW;AACf;;AAEA;IACI,UAAU;IACV,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,wBAAwB;IACxB,qBAAqB;IACrB,wCAAwC;IACxC,yDAAyD;IACzD,aAAa;IACb,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,4BAA4B;IAC5B,kBAAkB;IAClB,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,gCAAgC;IAChC;QACI,qBAAqB;IACzB;;AAEJ","sourcesContent":[".player_container {\r\n    font-size: 5.3vh;\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n    justify-content: space-between;\r\n}\r\n\r\n.player_counter {\r\n    display: block;\r\n    width: 4vw;\r\n    background-color: black;\r\n    color: white;\r\n}\r\n\r\n.controls {\r\n    display: flex;\r\n    flex-direction: row;\r\n}\r\n\r\n.controlButtons {\r\n    text-align: center;\r\n    font-size: 1vw;\r\n    font-weight: 900;\r\n    width: 5vh;\r\n    height: 5vh;\r\n}\r\n\r\n.numberInput {\r\n    width: 2vw;\r\n    font-size: 3vh;\r\n}\r\n\r\n.playerInput {\r\n    font-size: 3vh;\r\n    width: 8vw;\r\n}\r\n\r\n.radio {\r\n    --primary: #f3f3f4;\r\n    --secondary: #FAFBFF;\r\n    -webkit-appearance: none;\r\n    -moz-appearance: none;\r\n    -webkit-tap-highlight-color: transparent;\r\n    -webkit-mask-image: -webkit-radial-gradient(white, black);\r\n    outline: none;\r\n    cursor: pointer;\r\n    position: relative;\r\n    overflow: hidden;\r\n    transform-style: preserve-3d;\r\n    perspective: 240px;\r\n    border-radius: 50%;\r\n    width: 36px;\r\n    height: 36px;\r\n    border: 4px solid var(--primary);\r\n    &:checked {\r\n        background-color: red;\r\n    }\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"player_container": `Player_player_container__SDoY3`,
	"player_counter": `Player_player_counter__I-PO+`,
	"controls": `Player_controls__b-WT3`,
	"controlButtons": `Player_controlButtons__MPwNK`,
	"numberInput": `Player_numberInput__g8IK3`,
	"playerInput": `Player_playerInput__c5yQF`,
	"radio": `Player_radio__mXqKn`
};
export default ___CSS_LOADER_EXPORT___;
