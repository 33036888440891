// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Display_teamContainer__l-Fz4 {
  display: flex;
  flex-direction: row;
  margin-top: -1vh;
}

.Display_parent__nZjMx {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  width: 100vw;
  max-height: 53vh;
  }
    
.Display_div1__T7y8d { grid-area: 1 / 1 / 4 / 4; }
.Display_div2__509Wn { grid-area: 1 / 8 / 4 / 11; }
.Display_div3__cUpbw { grid-area: 1 / 4 / 2 / 6; }
.Display_div4__DG83Q { grid-area: 1 / 6 / 2 / 8; }
.Display_div5__IBWBE { 
  grid-area: 2 / 4 / 4 / 8; 
  display: flex;
  flex-direction: column;
  justify-content: center;

  .Display_span__HKlbi {
    margin: 0px
  }
} 


.Display_controlContainer__N-sPi {
  position: absolute;
  bottom: 0;
  width: 100vw;
}


.Display_goalDisplay__uhcsl {
  width: 100vw;
  height: 90vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .Display_goalTeam__Es7Bb {
    font-size: 12vh;
    margin: 0;

    margin-bottom: 10vh;
  }

  .Display_goalPlayer__wp5P0 {
    font-size: 12vh;
    margin: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Display.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sCAAsC;EACtC,kCAAkC;EAClC,oBAAoB;EACpB,iBAAiB;EACjB,YAAY;EACZ,gBAAgB;EAChB;;AAEF,uBAAQ,wBAAwB,EAAE;AAClC,uBAAQ,yBAAyB,EAAE;AACnC,uBAAQ,wBAAwB,EAAE;AAClC,uBAAQ,wBAAwB,EAAE;AAClC;EACE,wBAAwB;EACxB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;;EAEvB;IACE;EACF;AACF;;;AAGA;EACE,kBAAkB;EAClB,SAAS;EACT,YAAY;AACd;;;AAGA;EACE,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,yBAAyB;EACzB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;;EAEvB;IACE,eAAe;IACf,SAAS;;IAET,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,SAAS;EACX;AACF","sourcesContent":[".teamContainer {\r\n  display: flex;\r\n  flex-direction: row;\r\n  margin-top: -1vh;\r\n}\r\n\r\n.parent {\r\n  display: grid;\r\n  grid-template-columns: repeat(10, 1fr);\r\n  grid-template-rows: repeat(3, 1fr);\r\n  grid-column-gap: 0px;\r\n  grid-row-gap: 0px;\r\n  width: 100vw;\r\n  max-height: 53vh;\r\n  }\r\n    \r\n.div1 { grid-area: 1 / 1 / 4 / 4; }\r\n.div2 { grid-area: 1 / 8 / 4 / 11; }\r\n.div3 { grid-area: 1 / 4 / 2 / 6; }\r\n.div4 { grid-area: 1 / 6 / 2 / 8; }\r\n.div5 { \r\n  grid-area: 2 / 4 / 4 / 8; \r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n\r\n  .span {\r\n    margin: 0px\r\n  }\r\n} \r\n\r\n\r\n.controlContainer {\r\n  position: absolute;\r\n  bottom: 0;\r\n  width: 100vw;\r\n}\r\n\r\n\r\n.goalDisplay {\r\n  width: 100vw;\r\n  height: 90vh;\r\n  position: absolute;\r\n  top: 0;\r\n  left: 0;\r\n  background-color: #282c34;\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n\r\n  .goalTeam {\r\n    font-size: 12vh;\r\n    margin: 0;\r\n\r\n    margin-bottom: 10vh;\r\n  }\r\n\r\n  .goalPlayer {\r\n    font-size: 12vh;\r\n    margin: 0;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"teamContainer": `Display_teamContainer__l-Fz4`,
	"parent": `Display_parent__nZjMx`,
	"div1": `Display_div1__T7y8d`,
	"div2": `Display_div2__509Wn`,
	"div3": `Display_div3__cUpbw`,
	"div4": `Display_div4__DG83Q`,
	"div5": `Display_div5__IBWBE`,
	"span": `Display_span__HKlbi`,
	"controlContainer": `Display_controlContainer__N-sPi`,
	"goalDisplay": `Display_goalDisplay__uhcsl`,
	"goalTeam": `Display_goalTeam__Es7Bb`,
	"goalPlayer": `Display_goalPlayer__wp5P0`
};
export default ___CSS_LOADER_EXPORT___;
