// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Team_team_container__8xTCL {
    border: 5px solid #c2c2c2;
    padding: 1vw;
}

.Team_playersContainer__CIE-B {
    display: flex;
    flex-direction: column;
    gap: 1vh;
}
`, "",{"version":3,"sources":["webpack://./src/components/Team.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;AACZ","sourcesContent":[".team_container {\r\n    border: 5px solid #c2c2c2;\r\n    padding: 1vw;\r\n}\r\n\r\n.playersContainer {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 1vh;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"team_container": `Team_team_container__8xTCL`,
	"playersContainer": `Team_playersContainer__CIE-B`
};
export default ___CSS_LOADER_EXPORT___;
