// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.Logos_logoContainer__BsoYJ {
    width: 100vw;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 13vh;
    position: absolute;
    bottom: 0;
  }
  
  .Logos_fixedLogo__PT9O0 {
    margin-left: 1vw;
    width: 50vw;
    display: flex;
    flex-direction: row;
    justify-content: left;
    gap: 2vw;
    img {
      width: 6.0vw;
      height: 6.0vw;
    }
  }
  
  
  .Logos_movingLogosContainer__du-Cy {
    overflow: hidden;
  }
  
  .Logos_blank__\\+-gM\\+ {
    width: 4vw;
  }
  
  .Logos_movingLogos__Ozgm0 {
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    animation: Logos_scrollLogo__XB2un 20s infinite linear;
    white-space: nowrap;
  
    img {
      height: 6.0vw;
    }
  }
  
  @keyframes Logos_scrollLogo__XB2un {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-50%);
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Logos.module.css"],"names":[],"mappings":";AACA;IACI,YAAY;IACZ,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;IAClB,SAAS;EACX;;EAEA;IACE,gBAAgB;IAChB,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,qBAAqB;IACrB,QAAQ;IACR;MACE,YAAY;MACZ,aAAa;IACf;EACF;;;EAGA;IACE,gBAAgB;EAClB;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,uBAAkB;IAAlB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,sDAAyC;IACzC,mBAAmB;;IAEnB;MACE,aAAa;IACf;EACF;;EAEA;IACE;MACE,yBAAyB;IAC3B;IACA;MACE,2BAA2B;IAC7B;EACF","sourcesContent":["\r\n.logoContainer {\r\n    width: 100vw;\r\n    overflow: hidden;\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n    height: 13vh;\r\n    position: absolute;\r\n    bottom: 0;\r\n  }\r\n  \r\n  .fixedLogo {\r\n    margin-left: 1vw;\r\n    width: 50vw;\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: left;\r\n    gap: 2vw;\r\n    img {\r\n      width: 6.0vw;\r\n      height: 6.0vw;\r\n    }\r\n  }\r\n  \r\n  \r\n  .movingLogosContainer {\r\n    overflow: hidden;\r\n  }\r\n  \r\n  .blank {\r\n    width: 4vw;\r\n  }\r\n  \r\n  .movingLogos {\r\n    width: fit-content;\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-evenly;\r\n    animation: scrollLogo 20s infinite linear;\r\n    white-space: nowrap;\r\n  \r\n    img {\r\n      height: 6.0vw;\r\n    }\r\n  }\r\n  \r\n  @keyframes scrollLogo {\r\n    from {\r\n      transform: translateX(0%);\r\n    }\r\n    to {\r\n      transform: translateX(-50%);\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoContainer": `Logos_logoContainer__BsoYJ`,
	"fixedLogo": `Logos_fixedLogo__PT9O0`,
	"movingLogosContainer": `Logos_movingLogosContainer__du-Cy`,
	"blank": `Logos_blank__+-gM+`,
	"movingLogos": `Logos_movingLogos__Ozgm0`,
	"scrollLogo": `Logos_scrollLogo__XB2un`
};
export default ___CSS_LOADER_EXPORT___;
