import styles from './Team.module.css';
import Player from './Player';


export default function Team({name, players, counters, goalDelay, setCounters, controls=false, setTeamPlayers=null, setGoalDisplay=null, setShowGoalDisplay=null}) {
    const displayGoal = (playerName, playerTeam) =>
        {
            setGoalDisplay({"name": playerName, "team": playerTeam});
            setTimeout(() => {
                setShowGoalDisplay(true);
            }, 100)
            setTimeout(() => {
                setGoalDisplay({"name": "", "team": ""});
                setShowGoalDisplay(false);
            }, goalDelay)
        }
  
    return (
        <div className={styles.team_container}>
            <div className={styles.playersContainer}>
                {players.filter((player) => player.playing || controls).map((player, i) => <Player 
                    teamName={name}
                    player={player} 
                    value={counters[i]}
                    key={i} 
                    onIncrement={(player, teamName) => {
                        const countersCopy = [...counters];
                        countersCopy[i] += 1;
                        setCounters(countersCopy);
                        displayGoal(player.name, teamName);
                    }}
                    onDecrement={() => {
                        const countersCopy = [...counters];
                        countersCopy[i] -= 1;
                        setCounters(countersCopy);
                    }}
                    controls={controls}
                    index={i}
                    setTeamPlayers={(newPlayingBool, newNumber, newName) => {
                        const playersCopy = [...players];
                        playersCopy[i].playing = newPlayingBool;
                        playersCopy[i].name = newName;
                        playersCopy[i].number = newNumber;
                        setTeamPlayers(playersCopy);
                    }}
                />)}
            </div>

        </div>
    )
  }
  
  