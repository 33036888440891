import styles from './TeamName.module.css';

export default function TeamName({teamName, controls=false, setTeamName=null, left=false}) {

    const separatorDirection = left ? styles.left : styles.right

    return (
        <div className={`${styles.nameLayout} ${separatorDirection}`}>
            {controls && 
                <input 
                    className={styles.teamName}
                    value={teamName}
                    onChange={e => {
                            setTeamName(e.target.value);
                        }
                    }
                    // key={teamName}
                >
                </input>}
            {!controls && <p className={styles.teamName}>{teamName}</p>}
        </div>
    )
  }
  
  