// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Timer_red__a5iik {
    color: red;
}

.Timer_normal__9lwUC {
    color: inherit;
}

.Timer_label__sdsZC {
    font-size: 5vw;
    line-height: 8vh;
}

.Timer_inactive__KiB30 {
    font-size: 4vw;
}

.Timer_active__ubNu1 {
    font-size: 8vw;
}`, "",{"version":3,"sources":["webpack://./src/components/Timer.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".red {\r\n    color: red;\r\n}\r\n\r\n.normal {\r\n    color: inherit;\r\n}\r\n\r\n.label {\r\n    font-size: 5vw;\r\n    line-height: 8vh;\r\n}\r\n\r\n.inactive {\r\n    font-size: 4vw;\r\n}\r\n\r\n.active {\r\n    font-size: 8vw;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"red": `Timer_red__a5iik`,
	"normal": `Timer_normal__9lwUC`,
	"label": `Timer_label__sdsZC`,
	"inactive": `Timer_inactive__KiB30`,
	"active": `Timer_active__ubNu1`
};
export default ___CSS_LOADER_EXPORT___;
