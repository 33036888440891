import React, {useEffect, useState} from 'react';
import { useStopwatch } from "react-use-precision-timer";
import { useCrossTabState } from "../hooks";

import styles from './Display.module.css';
import Team from '../components/Team';
import TeamCounter from '../components/TeamCounter';
import TeamName from '../components/TeamName';
import Timer from '../components/Timer';
import Controls from '../components/Controls';
import Logos from '../components/Logos';

const MAX_HALFTIME_TIME = 180
const MAX_TIMEOUT_TIME = 30
const MAX_GAME_TIME = 600
const MAX_OVERTIME_TIME = 180
const GOAL_DELAY = 5000  // in ms

export default function Display({controls=false}) {

    const teamAData = [
                {
                "number": 1,
                "name": "Joueur 1",
                "playing": true
                },
                {
                "number": 2,
                "name": "Joueur 2",
                "playing": true
                },
                {
                "number": 3,
                "name": "Joueur 3",
                "playing": true
                },
                {
                "number": 4,
                "name": "Joueur 4",
                "playing": true
                },
                {
                "number": 5,
                "name": "Joueur 5",
                "playing": true
                },
                {
                "number": 6,
                "name": "Joueur 6",
                "playing": true
                },
            ]
    const teamNameA = "Cluny 1"

    const teamBData = [
                {
                "number": 1,
                "name": "Joueur 1",
                "playing": true
                },
                {
                "number": 2,
                "name": "Joueur 2",
                "playing": true
                },
                {
                "number": 3,
                "name": "Joueur 3",
                "playing": true
                },
                {
                "number": 4,
                "name": "Joueur 4",
                "playing": true
                },
                {
                "number": 5,
                "name": "Joueur 5",
                "playing": true
                },
                {
                "number": 6,
                "name": "Joueur 6",
                "playing": true
                },
            ]
    const teamNameB= "Cluny 2"
    

    const [countersTeamA, setcountersTeamA] = useCrossTabState("countersTeamA", [0,0,0,0,0,0])
    const sumTeamA = countersTeamA.reduce((acc, item) => acc + item, 0);
  
    const [countersTeamB, setcountersTeamB] = useCrossTabState("countersTeamB", [0,0,0,0,0,0])
    const sumTeamB = countersTeamB.reduce((acc, item) => acc + item, 0);
  
    const [teamAName, setTeamAName] = useCrossTabState("teamAName", teamNameA)
    const [teamAPlayers, setTeamAPlayers] = useCrossTabState("teamAPlayers", teamAData)
    const [teamBName, setTeamBName] = useCrossTabState("teamBName", teamNameB)
    const [teamBPlayers, setTeamBPlayers] = useCrossTabState("teamBPlayers", teamBData)

    const [gameTime, setGameTime] = useCrossTabState("gameTime", 0)
    const [timeoutTime, setTimeoutTime] = useCrossTabState("timeoutTime", 0)
    const [halftimeTime, setHalfTime] = useCrossTabState("halfTime", 0)
    const [overtimeTime, setOvertime] = useCrossTabState("overTime", 0)
  
    const [gameState, setGameState] = useCrossTabState("gameState", "waiting")
    const [goalDisplay, setGoalDisplay] = useCrossTabState("goalDisplay", {"name": "", "team": ""})
    const [showGoalDisplay, setShowGoalDisplay] = useCrossTabState("showGoalDisplay", "")

    const [halftimeTimerComplete, setHalftimeTimerComplete] = useCrossTabState("halftimeTimerComplete", false)
    const [timeoutTimerComplete, setTimeoutTimerComplete] = useCrossTabState("timeoutTimerComplete", false)
    const [gameTimerComplete, setGameTimerComplete] = useCrossTabState("gameTimerComplete", false)
    const [overtimeTimerComplete, setOvertimeTimerComplete] = useCrossTabState("overtimeTimerComplete", false)

    let soundPlaying = false;

    const gameStopwatch = useStopwatch();
    const timeoutStopwatch = useStopwatch();
    const halftimeStopwatch = useStopwatch();
    const overTimeStopwatch = useStopwatch();

    function playSoundEffect() {
      if (!soundPlaying) {
        soundPlaying = true;
        var audio = new Audio('buzzer.mp3');
        audio.play();

        setInterval(() => {
          soundPlaying = false
        }, 2000)
      }
    }


    // Handle refresh rate of counters
    useEffect(() => {
      if (controls) {
        const timerId = setInterval(() => {
          const gameTime = gameStopwatch.getElapsedRunningTime(); 
          const timeout = timeoutStopwatch.getElapsedRunningTime(); 
          const halfTime = halftimeStopwatch.getElapsedRunningTime(); 
          const overTime = overTimeStopwatch.getElapsedRunningTime(); 
          if (timeout > MAX_TIMEOUT_TIME * 1000) {
            playSoundEffect();
            setTimeoutTimerComplete(true);
            timeoutStopwatch.stop()
          }
          if (halfTime > MAX_HALFTIME_TIME * 1000) {
            playSoundEffect();
            setHalftimeTimerComplete(true);
            halftimeStopwatch.stop()
          }
          if (gameTime > MAX_GAME_TIME * 1000) {
            playSoundEffect();
            setGameTimerComplete(true);
            gameStopwatch.stop()
          }
          if (overTime > MAX_OVERTIME_TIME * 1000) {
            playSoundEffect();
            setOvertimeTimerComplete(true);
            overTimeStopwatch.stop()
          }
          setGameTime(gameTime);
          setTimeoutTime(timeout);
          setHalfTime(halfTime);
          setOvertime(overTime);
        }, 100);
    
        return () => {
          clearInterval(timerId);
        };
      }

    }, []);

    let gameData = {
        "state": gameState,
        "teamAName": teamAName,
        "teamAPlayers": teamAPlayers,
        "teamAScore": countersTeamA,
        "teamBName": teamBName,
        "teamBPlayers": teamBPlayers,
        "teamBScore": countersTeamB,
        "gameTime": gameTime,
        "timeoutTime": timeoutTime,
        "halftimeTime": halftimeTime,
        "overtimeTime": overtimeTime,
        "halftimeTimerComplete": halftimeTimerComplete,
        "timeoutTimerComplete": timeoutTimerComplete,
        "gameTimerComplete": gameTimerComplete,
        "overtimeTimerComplete": overtimeTimerComplete
    }

    const resetTeamPlayers = (teamPlayersA, teamPlayersB) => {
      const playersACopy = [...teamPlayersA];
      for (let playerIndex in playersACopy) {
        playersACopy[playerIndex].playing = false
      }
      setTeamAPlayers(playersACopy)

      const playersBCopy = [...teamPlayersB];
      for (let playerIndex in playersBCopy) {
        playersBCopy[playerIndex].playing = false
      }
      setTeamBPlayers(playersBCopy)

      setTimeout(() => {
        const playersACopy2 = [...teamPlayersA];
        for (let playerIndex in playersACopy2) {
          playersACopy2[playerIndex].playing = true
        }
        setTeamAPlayers(playersACopy2)
      }, 200);
      setTimeout(() => {
        const playersBCopy2 = [...teamPlayersB];
        for (let playerIndex in playersBCopy2) {
          playersBCopy2[playerIndex].playing = true
        }
        setTeamBPlayers(playersBCopy2)
      }, 200);
    } 

    const initGame = (gameContent) => {
      setGameState(gameContent.state)

      // Setup team
      setTeamAName(gameContent.teamAName)
      setcountersTeamA(gameContent.teamAScore)
      setTeamBName(gameContent.teamBName)
      setcountersTeamB(gameContent.teamBScore)

      // // Setup timers
      // gameStopwatch.stop(); 
      // timeoutStopwatch.stop(); 
      // halftimeStopwatch.stop(); 
      // overTimeStopwatch.stop(); 

      // gameStopwatch.start(gameContent.gameTime); 
      // gameStopwatch.pause(); 
      // timeoutStopwatch.start(gameContent.timeoutTime); 
      // timeoutStopwatch.pause(); 
      // halftimeStopwatch.start(gameContent.halftimeTime); 
      // halftimeStopwatch.pause(); 
      // overTimeStopwatch.start(gameContent.overtimeTime); 
      // overTimeStopwatch.pause(); 

      // setGameTime(gameContent.gameTime)
      // setTimeoutTime(gameContent.timeoutTime)
      // setHalfTime(gameContent.halftimeTime)
      // setOvertime(gameContent.overtimeTime)

      // setHalftimeTimerComplete(gameContent.halftimeTimerComplete)
      // setTimeoutTimerComplete(gameContent.timeoutTimerComplete)
      // setGameTimerComplete(gameContent.gameTimerComplete)
      // setOvertimeTimerComplete(gameContent.overtimeTimerComplete)

      // Setup display
      setGoalDisplay({"name": "", "team": ""})
      setShowGoalDisplay("")

      // Refresh players
      resetTeamPlayers(gameContent.teamAPlayers, gameContent.teamBPlayers)
    }

    const resetGame = () => {
      setGameState("waiting");
      // Setup team
      setTeamAName(teamNameA)
      setTeamAPlayers(teamAData)
      setcountersTeamA([0,0,0,0,0,0])
      setTeamBName(teamNameB)
      setTeamBPlayers(teamBData)
      setcountersTeamB([0,0,0,0,0,0])

      // Setup timers
      setGameTime(0)
      setTimeoutTime(0)
      setHalfTime(0)

      // Setup display
      setGoalDisplay({"name": "", "team": ""})
      setShowGoalDisplay("")
    }

    return (
      <div className="App">
        <header className="App-header">
          { !controls && <img src='/logos/bandeau.svg'/>}
          { (!controls && showGoalDisplay) && <div className={styles.goalDisplay}>
              <p className={styles.goalTeam}>{goalDisplay.team}</p>
              <p className={styles.goalPlayer}>But de <br/>{goalDisplay.name}</p>
            </div>}
          <div className={styles.teamContainer}>        
              <TeamName 
                teamName={teamAName}
                controls={controls}
                setTeamName={setTeamAName} 
                left={true}
              /> 
              <TeamName 
                teamName={teamBName}
                controls={controls}
                setTeamName={setTeamBName} 
              />
          </div>
          <div className={styles.parent}>
            <div className={styles.div1}>
              <Team 
                name={teamAName}
                players={teamAPlayers}
                setTeamPlayers={setTeamAPlayers}
                counters={countersTeamA}
                goalDelay={GOAL_DELAY}
                setCounters={setcountersTeamA}
                controls={controls}
                setGoalDisplay={setGoalDisplay}
                setShowGoalDisplay={setShowGoalDisplay}
              />
            </div>
            <div className={styles.div2}>
              <Team 
                name={teamBName}
                players={teamBPlayers}
                setTeamPlayers={setTeamBPlayers}
                counters={countersTeamB}
                goalDelay={GOAL_DELAY}
                setCounters={setcountersTeamB}
                controls={controls}
                setGoalDisplay={setGoalDisplay}
                setShowGoalDisplay={setShowGoalDisplay}
              />
            </div>
            <div className={styles.div3}>        
              <TeamCounter 
                value={sumTeamA}
                left={true}
              />
            </div>
            <div className={styles.div4}>        
              <TeamCounter 
                value={sumTeamB}
              />
            </div>
            <div className={styles.div5}>
              {(!(gameState == "overtime") && !(gameState == "halftime")) && <Timer runningTime={gameTime} maxTime={MAX_GAME_TIME} stopped={gameTimerComplete} active={(gameState == "timeout" || gameState == "halftime") ? false : true}/>}
              {gameState == "timeout" && <Timer runningTime={timeoutTime} maxTime={MAX_TIMEOUT_TIME} label={"Temps mort"} stopped={timeoutTimerComplete}/>}
              {gameState == "halftime" && <Timer runningTime={halftimeTime} maxTime={MAX_HALFTIME_TIME} label={"Mi-temps"} stopped={halftimeTimerComplete}/>}
              {gameState == "overtime" && <Timer runningTime={overtimeTime} maxTime={MAX_OVERTIME_TIME} label={"But en or"} stopped={overtimeTimerComplete}/>}
              
            </div>
            { controls && <div className={styles.controlContainer}>
              <Controls 
                gameStopwatch={gameStopwatch}
                halftimeStopwatch={halftimeStopwatch}
                timeoutStopwatch={timeoutStopwatch}
                overTimeStopwatch={overTimeStopwatch}
                setGameState={setGameState}
                gameState={gameState}
                gameData={gameData}
                initGame={initGame}
                reset={resetGame}
                setTimeoutTimerComplete={setTimeoutTimerComplete}
                setHalftimeTimerComplete={setHalftimeTimerComplete}
                setGameTimerComplete={setGameTimerComplete}
                setOverTimeTimerComplete={setOvertimeTimerComplete}
              />
              </div>
              }
          </div> 
          { !controls && <Logos /> }
        </header>
      </div>
    );
  }
