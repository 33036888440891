import React, {useEffect} from 'react';
import { Routes, Route, Outlet, Link } from "react-router-dom";
import Display from './pages/Display';
import './App.css';

export default function App() {
  return (
    <div>
      <Routes>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="/score" element={<Score />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </div>
  );
}


function Home() {
  return (
    <Display controls={true}/>
  );
}

function Score() {
  return (
    <Display controls={false}/>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Not found</h2>
    </div>
  );
}
