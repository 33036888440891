import styles from './TeamCounter.module.css';

export default function TeamCounter({value, left=false}) {

    const separatorDirection = left ? styles.left : styles.right

    return (
        <p className={`${styles.score} ${separatorDirection}`}>{value}</p>
    )
  }
  
  