// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TeamCounter_score__Cle0X {
    font-size: 10vw;
    margin: 0;
    height: 100%;
    line-height: 12vh;
}

.TeamCounter_left__u7Vz9 {
    border-right: 5px solid black;
}

.TeamCounter_right__4ufsK {
    border-left: 5px solid black;
}`, "",{"version":3,"sources":["webpack://./src/components/TeamCounter.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,SAAS;IACT,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,4BAA4B;AAChC","sourcesContent":[".score {\r\n    font-size: 10vw;\r\n    margin: 0;\r\n    height: 100%;\r\n    line-height: 12vh;\r\n}\r\n\r\n.left {\r\n    border-right: 5px solid black;\r\n}\r\n\r\n.right {\r\n    border-left: 5px solid black;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"score": `TeamCounter_score__Cle0X`,
	"left": `TeamCounter_left__u7Vz9`,
	"right": `TeamCounter_right__4ufsK`
};
export default ___CSS_LOADER_EXPORT___;
