// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TeamName_nameLayout__7O3\\+p {
    height: 100%;
    align-content: center;
}

.TeamName_teamName__EhUuF {
    font-size: 5vw;
    margin: 0px;
    padding-bottom: 3vh;
    width: 49vw;
    line-height: 9vh;
}

.TeamName_left__F-bSc {
    border-right: 5px solid black;
}

.TeamName_right__ArpwR {
    border-left: 5px solid black;
}`, "",{"version":3,"sources":["webpack://./src/components/TeamName.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,cAAc;IACd,WAAW;IACX,mBAAmB;IACnB,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,4BAA4B;AAChC","sourcesContent":[".nameLayout {\r\n    height: 100%;\r\n    align-content: center;\r\n}\r\n\r\n.teamName {\r\n    font-size: 5vw;\r\n    margin: 0px;\r\n    padding-bottom: 3vh;\r\n    width: 49vw;\r\n    line-height: 9vh;\r\n}\r\n\r\n.left {\r\n    border-right: 5px solid black;\r\n}\r\n\r\n.right {\r\n    border-left: 5px solid black;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nameLayout": `TeamName_nameLayout__7O3+p`,
	"teamName": `TeamName_teamName__EhUuF`,
	"left": `TeamName_left__F-bSc`,
	"right": `TeamName_right__ArpwR`
};
export default ___CSS_LOADER_EXPORT___;
