import { type } from '@testing-library/user-event/dist/type';
import styles from './Player.module.css';

export default function Player({teamName, player, value, onIncrement, onDecrement, controls=false, setTeamPlayers=null}) {
    return (
        <div className={styles.player_container}>
            {controls && 
                <input 
                    className={styles.radio}
                    defaultValue={player.playing}
                    onChange={e => {
                        setTeamPlayers(!e.target.checked, player.number, player.name);
                        }
                    }
                    type='checkbox'
                >
                </input>}
            {controls && player.playing &&
                <input 
                    className={styles.numberInput}
                    defaultValue={player.number}
                    onChange={e => {
                        setTeamPlayers(player.playing, e.target.value, player.name);
                    }
                }
                >
                </input>}
            {!controls && <span>{player.number}</span>}
            {controls  && player.playing &&  
                <input 
                    className={styles.playerInput}
                    defaultValue={player.name}
                    onChange={e => {
                        setTeamPlayers(player.playing, player.number, e.target.value);
                    }
                }
                >
                </input>}
            {!controls && <span>{player.name}</span>}
            {controls  && player.playing && <div className={styles.controls}>
                <button className={styles.controlButtons} onClick={() => {onIncrement(player, teamName);}}>+</button> 
                <button className={styles.controlButtons} onClick={() => {onDecrement();}}>-</button> 
            </div>
            }

            {controls  && player.playing && <span className={styles.player_counter}>{value}</span>}
            {!controls && <span className={styles.player_counter}>{value}</span>}
            
        </div>
    )
  }
  
  